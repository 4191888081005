import React from "react";

import './header.css';

const Header = () => {
     return(
          <div className="__header d-flex align-items-center">
               <h3 className="container">Admin panel</h3>
          </div>
     );
}

export default Header;