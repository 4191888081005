import React, { useState, useEffect } from 'react'
import axiosInstance from '../../http/axios';

import Toast from '../../toast';

import { TextField, Button, Fab, IconButton, Switch } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

import Header from '../header/Header';

const Setting = ()=>{

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          forceUpdateEnabled: false,
          versionCode: 1,
          loginAddress: "",
          razorpayKey: "",
          loginPassword: "",
          barberPartnerTermsAndConditionsLink: "",
          barberPartnerPrivacyPolicyLink: "",
          aboutUsLink: "",
          appLink: "",
          howToUseLink: "",
          helpAndSupportEmail: "",
          privacyPolicyLink: "",
          connectUsLink: ""
     });

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const getdata = async () => {
          try {
               const res = await axiosInstance.get('admin/setting');
               console.log(res.data);
               setData(res.data);
          } 
          catch (error) {
               Toast.error('Failed');
          }
     }

     useEffect( ()=>{
          getdata();
          
     }, []);

     const onChange = (e)=>{
          setData({
               ...data,
               [e.target.name]: e.target.value,
          });
     }

     const validate = () => {
          if(!data.password){
               Toast.error("Please enter password");
               return false;
          }
          return true;
     }

     const onSubmit = async ()=>{

          setIsSending(true)
          try {
               const rBody = new FormData();
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               const res = await axiosInstance.patch("admin/setting", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               );
               setData(res.data);

               Toast.success('Saved');
               setIsSending(false)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed to update');
          }
     }

     return(
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>navigate(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Setting</h5>
                    </div>
               </div>
               <div className="d-flex justify-content-center" style={{height:'auto', marginTop: '10px'}}>
                    <div className='notification_container d-flex align-items-center flex-column'>
                         {/* <div style={{width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop:'20px'}}>
                              Force update <Switch checked={data.forceUpdateEnabled} onChange={(e, is)=>setData({
                                        ...data,
                                        forceUpdateEnabled: is
                                   })} />
                         </div> */}
                         {/* <TextField name='versionCode' value={data.versionCode} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Version code" type="number"/> */}
                         <TextField name='razorpayKey' value={data.razorpayKey} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Razorpay Key" type="text"/>
                         <TextField name='loginAddress' value={data.loginAddress} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Login Email" type="text"/>
                         <TextField name='loginPassword' value={data.loginPassword} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Login Password" type="text"/>
                         <TextField name='appLink' value={data.appLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="App Download Link" type="text"/>
                         <TextField name='barberPartnerTermsAndConditionsLink' value={data.barberPartnerTermsAndConditionsLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Terms And Conditions Link (Partner App)" type="text"/>
                         <TextField name='barberPartnerPrivacyPolicyLink' value={data.barberPartnerPrivacyPolicyLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Privacy Policy Link (Partner App)" type="text"/>
                         <TextField name='aboutUsLink' value={data.aboutUsLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="About Us Link" type="text"/>
                         <TextField name='helpAndSupportEmail' value={data.helpAndSupportEmail} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Help And Support Email" type="text"/>
                         <TextField name='privacyPolicyLink' value={data.privacyPolicyLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Privacy Policy Link " type="text"/>
                         <TextField name='connectUsLink' value={data.connectUsLink} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Connect Us Link" type="text"/>
                         
                         <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', marginBottom: "30px", cursor: isSending ? "progress" : 'pointer'}} className="__btn">Save</button>
                    </div>
               </div>
          </>
     );
}

export default Setting;