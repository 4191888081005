import React, { useEffect, useState } from "react";
import './App.css'
import { Routes, Route, Navigate } from 'react-router-dom'

import Login from "./components/login/Login";
import Deshboard from "./components/deshboard/Deshboard";
import Setting from './components/setting/Setting';
import Home from './components/home/Home';
import axiosInstance from "./http/axios";
import Toast from "./toast";
import Cookie from "./Cookie";
import WithdrawalRequest from './components/withdrawal_request/WithdrawalRequest';
import Banner from './components/banner/Banner';
import CreateBanner from './components/banner/CreateBanner';
import Users from "./components/users/Users";
import Orders from "./components/order/Orders";
import Barbers from "./components/barber/Barbers";
import Services from "./components/services/Services";
import CreateService from "./components/services/CreateService";
import EditService from "./components/services/EditService";
import BarberForm from "./components/barber_form/BarberForm";
import BarberFormFullPage from "./components/barber_form/BarberFormFullPage";

const App = ()=>{

     const [Admin, setAdmin] = useState([]);

     const getdata = async ()=> {
          if(!Cookie.getCookie("authx")){
               return;
          }
          try {
               const res = await axiosInstance.get('admin/home-data');
               setAdmin(res.data);
          } 
          catch (error) {
               Toast.error('Failed');
          }
     }

     // useEffect(()=>{

     //      getdata();


     // }, []);     

     

     if(!Cookie.getCookie("authx")){
          return(
               <>
                    <Routes>
                         <Route path="/admin-login" element={<Login/>}/>
                         <Route path='*' element={<h1>Page not found</h1>}/>
                    </Routes>
               </>
          );
     }

     return(
          <>
               <Routes>
                    
                    <Route path="/admin-dashboard" element={<Home><Deshboard/></Home>}/>
                    <Route path="/admin-banner" element={<Home><Banner/></Home>}/>
                    <Route path="/admin-create-banner" element={<Home><CreateBanner/></Home>}/>
                    <Route path="/admin-users" element={<Home><Users/></Home>}/>
                    <Route path="/admin-setting" element={<Home><Setting/></Home>}/>
                    <Route path="/admin-orders" element={<Home><Orders/></Home>}/>
                    <Route path="/admin-barbers" element={<Home><Barbers/></Home>}/>
                    <Route path="/admin-services" element={<Home><Services/></Home>}/>
                    <Route path="/admin-create-service" element={<Home><CreateService/></Home>}/>
                    <Route path="/admin-edit-service" element={<Home><EditService/></Home>}/>
                    <Route path="/admin-barber-form" element={<Home><BarberForm/></Home>}/>
                    <Route path="/admin-barber-form-data" element={<Home><BarberFormFullPage/></Home>}/>



                    {/* { accessEnabled(AdminAccess.VIEW_USER_LIST) ? <Route path="/admin-users" element={<Home Admin={Admin}><Users/></Home>}/> : null } */}
                    {/* <Route path="/admin-gifts" element={<Home Admin={Admin}><Gift/></Home>}/> */}
                    {/* <Route path="/admin-host-forms" element={<Home Admin={Admin}><HostForm/></Home>}/> */}
                    {/* <Route path="/admin-agent-forms" element={<Home Admin={Admin}><AgentForm/></Home>}/> */}
                    {/* <Route path="/admin-user-withdrawal-history" element={<Home><UserWithdrawalHistory/></Home>}/> */}
                    {/* <Route path="/admin-withdrawal-history" element={<Home><WithdrawalHistroy/></Home>}/> */}
                    <Route path="/admin-withdrawal-requests" element={<Home><WithdrawalRequest/></Home>}/>
                    <Route path='*' element={<Navigate to={"/admin-dashboard"}/>}/>
               </Routes>
          </>
     );
}

export default App;