import React from "react";
import AccessiblePages from "./AccessiblePages";

const Home = ({ children })=>{


     return(
          <div className="container-scroller">
               <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                         <a className="navbar-brand brand-logo" href="/admin-dashboard"><img src="assets/images/admin.png" alt="logo" /></a>
                         <a className="navbar-brand brand-logo-mini" href="/admin-dashboard"><img src="assets/images/admin-logo.png" alt="logo" /></a>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <div className="navbar-toggler navbar-toggler align-self-center d-flex align-items-center" type="button" data-toggle="minimize">
                         <span className="mdi mdi-menu"></span>
                    </div>
                    <ul className="navbar-nav navbar-nav-right">
                         <li className="nav-item d-none d-lg-block full-screen-link">
                              <a className="nav-link">
                                   <i className="mdi mdi-fullscreen" id="fullscreen-button"></i>
                              </a>
                              </li>
                         <li className="nav-item nav-logout d-none d-lg-block" onClick={()=>{
                                        document.cookie = 'authx=; Max-Age=0;secure';
                                        window.location.href = "/admin-login"
                                   }}>
                              <a className="nav-link">
                                   <i className="mdi mdi-power"></i>
                              </a>
                         </li>
                         <li className="nav-item nav-settings d-none d-lg-block">
                              <a className="nav-link" href="#">
                                   <i className="mdi mdi-format-line-spacing"></i>
                              </a>
                         </li>
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                         <span className="mdi mdi-menu"></span>
                    </button>
                    </div>
               </nav>
               
               <div className="container-fluid page-body-wrapper">
               
                    <nav className="sidebar sidebar-offcanvas" id="sidebar">
                         <ul className="nav">
                              {AccessiblePages.map((v)=>
                              <li className={"nav-item"}>
                                   <a className={"nav-link "} href={v.path} >
                                        <span style={{color: window.location.pathname.endsWith(v.path) ? "#b66dff" : "gray"}} className="menu-title">{v.leble}</span>
                                        <i style={{color: window.location.pathname.endsWith(v.path) ? "#b66dff" : "gray"}} className={"mdi menu-icon " + v.class}></i>
                                   </a>
                              </li> )}
                         </ul>
                    </nav>
                    
                    <div className="main-panel">
                         <div className="content-wrapper">
                              {children}
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default Home;