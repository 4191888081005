import React, { useState } from 'react';
import './Switch.css';

var i = 0;
const Switch = (props) => {
     i++;
     const checked = props.checked
  return (
    <div className='react-switch-root'>
      <input
          checked={checked}
          onChange={(e) => {
               if(props.onChange) props.onChange(e.target.checked);
          }}
        className="react-switch-checkbox"
        id={`react-switch-new-${i}`}
        type="checkbox"
      />
      <label
        className={"react-switch-label" + (checked ? " react-switch-label-checked" : "")}
        htmlFor={`react-switch-new-${i}`}
      >
        <span className={`react-switch-button`} />
      </label>
    </div>
  );
};

export default Switch;