import React, { useState, useEffect } from "react";
import axiosInstance  from "../../http/axios";

import Header from '../header/Header';
import { IconButton,  } from '@mui/material';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import Toast from '../../toast';

import './Deshboard.css'
import AccessiblePages from "../home/AccessiblePages";

const Deshboard = ({}) => {

     const [data, setData] = useState({
          users: 0,
          gifts: 0, 
          hostForms: 0,
          agents: 0,
          helpAndSupport: 0,
          withdrawalRequests: 0,
          banner: 0,
     });

     // useEffect(async ()=>{
     //      try {
     //           const res = await axiosInstance.get('admin/home-data');
     //           setData(res.data);
     //      } 
     //      catch (error) {
     //           Toast.error('Failed');
     //      }
     // }, []);


     return(
          <div>
               <div className="container">
                    <div className="row mt-4">

                         {
                              AccessiblePages.map((v)=> <Item className='col-3' length="" label={v.leble} value="" redirect={v.path} /> )
                         }

                         {/* <Item className='col-3' label="Users" value={data.users} redirect="/admin-users" />
                         <Item className='col-3' label="Withdrawal requests" value={data.withdrawalRequests} redirect="/admin-withdrawal-requests" />
                         <Item className='col-3' label="Gift's" value={data.gifts} redirect="/admin-gifts" />
                         <Item className='col-3' label="Host Form's" value={data.hostForms} redirect="/admin-host-forms" />
                         <Item className='col-3' label="Agent's" value={data.agents} redirect="/admin-agent" />

                         <Item className='col-3' label="Help & Support" value={data.helpAndSupport} redirect="/admin-help-and-support" />
                         <Item className='col-3' label="Banner" value={data.banner} redirect="/admin-banner" />
                         <Item className='col-3' label="Notification" value="" redirect="/admin-notification" />

                         <Item className='col-3' label="Setting" value="" redirect="/admin-setting" /> */}
                    </div>
               </div>
          </div>
     );
}

const Item = (props) => {
     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     return (
          <div style={{marginTop: "10px"}} className={"Deshboard_Item " + props.className} onClick={()=>navigate(props.redirect)}>
               <div className="Deshboard_Item_inner d-flex justify-content-between flex-column text-white">
                    <p style={{ fontSize: '20px', margin: '' }}>{props.label}</p>
                    <div className="d-flex justify-content-between align-items-baseline">
                         <p style={{ fontSize: '30px'}}>{props.length == -1 ? "" : props.length}</p>
                         <IconButton aria-label="delete" size="large">
                              <ArrowForwardRoundedIcon style={{ color: "#fff" }} fontSize="inherit"  />
                         </IconButton>
                    </div>
               </div>
          </div>
     );
}

export default Deshboard;