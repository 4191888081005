import React, {useState, useEffect} from 'react'
import axiosInstance from '../../http/axios';

import Toast from '../../toast';
import Header from '../header/Header';

import "bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';

import SearchOutlined from '@mui/icons-material/SearchOutlined';

import NavLink from '../nav_link/NavLink';
import { useNavigate } from 'react-router-dom';
import AdminAccess from '../../AdminAccess';

const Barbers = ({}) => {

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [errorMessage, setErrorMessage] = useState(null);
     const [userAccountStatus, setUserAccountStatus] = useState("active");
     const [searchQuery, setSearchQuery] = useState(null);
     const tableHead = ["Barber", "Mobile Number", "Balance", "Total Earning", "Services", "Area", "Status", ""];

     const onPageChange = async (e, pageIndex, status) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               params.append("filterByNew", true);

               if(searchQuery){
                    params.append("searchQuery", searchQuery);
               }
               if(status){
                    params.append("accountStatus", status);
               }
               else params.append("accountStatus", userAccountStatus);
               const res = await axiosInstance.get('admin/barbers', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
          }
     }

     useEffect(()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Barbers</h5>
                    </div>
                    <div className='d-flex align-items-center'>
                         <div className='d-flex align-items-center' style={{border: '1px solid #A29694', marginRight: "20px", padding: '7px 20px', borderRadius: "5px"}}>
                              <input type="text" style={{background: "#F2EDF3", border: 'none', marginRight: "20px", }} placeholder='Search by Barber id...' onChange={(e)=>setSearchQuery(e.target.value.trim())}/>
                              <SearchOutlined htmlColor='#A29694' onClick={()=>{
                                   onPageChange(null, 1, userAccountStatus);
                              }} />
                         </div>
                         {/* <DropdownMenu items={["Active users", "Blocked users"]} onChange={(value, i)=> {
                                   const status = i == 0 ? "active" : "blocked";
                                   setUserAccountStatus(status);
                                   onPageChange(null, 1, status);
                              }}/> */}
                    </div>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage&&data.length != 0 ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {

     const accessEnabled = access => props.Admin.access ? props.Admin.access.indexOf(access) != -1 : false;

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [ loading, setLoading] = useState(false); 

     const [data, setData] = useState({...props.data});
     
     const { _id, fcm_token, loginId, barberName, mobileNumber, formId, barberCode, accountStatus, services, balance, totalEarning, pincodeList, date } = data;

     useEffect(()=> {
          setData(props.data);


     }, [props.data]);


     const getMultiList = (list, col) => {
        let x = [];
        const new_list = [];
        for (let item of list){
            x.push(item);
            if(x.length == col){
                new_list.push(x);
                x = [];
            }
        }
        if(x.length != 0){
            new_list.push(x);
        }
        return new_list;
     }

     const submit = async (accountStatus, balance) => {
          if(loading) return;
          setLoading(true)
          try {
               const rBody = new FormData();
               rBody.append("userId", _id)
               if(accountStatus){
                    rBody.append("accountStatus", accountStatus)
               }
               if(balance){
                    rBody.append("balance", balance)
                    rBody.append("action", "add")
               }
               const res = await axiosInstance.patch("admin/barber", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               );
               setLoading(false);

               setData({
                    ...res.data
               });
          } 
          catch (error) {
               Toast.error("Failed to update");
               setLoading(false);
          }
     }

     const onAddBalance = () => {
          try{
               const input = prompt("Add Diamonds", 100);
               if(!input || isNaN(input)) return;

               submit(null, input);
          }
          catch(error){
               
          }
     }

     return(
          <tr><td>{barberName+ " (" + barberCode + ")"}</td>
               <td>{mobileNumber}</td>
               <td>₹{balance}</td>
               <td>₹{totalEarning}</td>
               <td>
                    {getMultiList(services, 3).map((val, i)=> (
                        <div style={{padding: '0'}}>
                            { val.map(val=> <span className='c-access-name unselected' style={{background: "rgb(97, 97, 97, .3)"}} >{val}</span>) }
                        </div>
                     ))}
                </td>
                <td>
                    {getMultiList(pincodeList, 4).map((val, i)=> (
                        <div style={{padding: '0'}}>
                            { val.map(val=> <span className='c-access-name unselected' style={{background: "rgb(97, 97, 97, .3)"}} >{val}</span>) }
                        </div>
                     ))}
                </td>
               <td><span>{accountStatus.replace("_", " ")}</span></td>
               { accountStatus == "suspended" || accountStatus ==  "active" ? <td>
                    <button className='item_action_btn' style={{background: accountStatus === "active" ? '#df1111' : 'green', color: '#fff'}} onClick={()=> submit(accountStatus === "active" ? "suspended" : "active")}>{accountStatus === "active" ? "Suspend" : "Activate"}</button>
               </td> : null}
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default Barbers;