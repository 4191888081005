import { toast } from 'react-toastify';


class Toast {

     static success(msg){
          toast.success(msg, {
               position: "bottom-right",
               autoClose: 2000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "colored",
          });
     }

     static error(msg){
          toast.error(msg, {
               position: "bottom-right",
               autoClose: 2000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "colored",
          });
     }
}

export default Toast;