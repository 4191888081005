import React, {useState, useEffect} from 'react'
import axiosInstance from '../../http/axios';

import Toast from '../../toast';
import Header from '../header/Header';

import "bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import NavLink from '../nav_link/NavLink';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const Orders = (props) => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [status, setStatus] = useState("new");
     const [errorMessage, setErrorMessage] = useState(null);
     const tableHead = ["Service", "Delevery Date & Time", "Customer M No.", "Customer City", "Customer Pincode", "Barber", "Date"];
     const selectionItems =  ["New", "Pending", "Completed", "Cancelled"];
     const selectionItemKeys = ["new", "pending", "complete", "cancelled"];

     const [date, setDate] = useState(dayjs(new Date("2024-01-01")));

     const onPageChange = async (e, pageIndex, mStatus, mdate) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               params.append("from", new Date(mdate||date.$d).getTime());
               if(mStatus){
                    params.append("status", mStatus);
               }
               else params.append("status", status);

               const res = await axiosInstance.get('admin/orders', {
                    params: params
               });
               console.log(res.data);
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
          }
     }

     useEffect(()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Orders</h5>
                    </div>
                    <div className='d-flex align-items-center'>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                   <DemoContainer components={['DatePicker']}>
                                        <DatePicker 
                                             className='DatePicker' 
                                             defaultValue={date}
                                             format="DD-MM-YYYY"
                                             maxDate={dayjs(new Date().toISOString().substring(0, 10))}
                                             onChange={(val)=> {
                                                  setDate((dayjs(new Date(val.$d))))
                                                  onPageChange(null, 1, null, val.$d)
                                             }}
                                             />
                                   </DemoContainer>
                              </LocalizationProvider>
                              <div style={{width: '20px'}}></div>
                         <DropdownMenu items={selectionItems} onChange={(value, i)=> {
                                   const status = selectionItemKeys[i];
                                   setStatus(status);
                                   onPageChange(null, 1, status);
                                   console.log(status)
                              }}/>
                    </div>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item currentStatus={status} data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { orderId , barberId, barberName, service, serviceDate, serviceTime, customerMobileNumber, customerCity, customerPincode, date } = props.data;

     // ["Service", "Delevery Date & Time", "Customer Name", "Customer City", "Customer Pincode", "Barber", "Date"];

     return(
          <tr>
               <td><span>{service.split("+").map(v=> <div>{v}</div>)}</span></td>
               <td><span>{serviceDate + " - " + serviceTime}</span></td>
               <td><span>{customerMobileNumber}</span></td>
               <td><span>{customerCity}</span></td>
               <td><span>{customerPincode}</span></td>
               <td><span>{barberName||"--"}</span></td>
               <td>{date}</td>
               {/* {status == 'pending' ? <td>
                    <button className='item_action_btn' style={{background: '#df1111', color: '#fff'}} onClick={()=>onAction('refunded')}>Refund</button>
                    <button className='item_action_btn' style={{background: '#df1111', color: '#fff'}} onClick={()=>onAction('rejected')}>Reject</button>
                    <button className='item_action_btn' style={{background: 'green', color: '#fff'}} onClick={()=>onAction('approved')}>Approve</button>
               </td> : null} */}
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div style={{height: "300px", fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
               {props.message}
          </div>
     );
}

export default Orders;