import React, {useState, useEffect} from 'react'
import axiosInstance from '../../http/axios';

import Toast from '../../toast';
import Header from '../header/Header';

import "bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import NavLink from '../nav_link/NavLink';

const BarberForm = (props) => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const [data, setData] = useState([]);
     const [maxLength, setMaxDataLength] = useState(0);
     const [status, setStatus] = useState("under_review");
     const [errorMessage, setErrorMessage] = useState(null);
     const tableHead = ["Form ID", "Barber Name","Mobile No.", "State Name", "Services", "Date"];
     const selectionItems =  ["Pending", "Approved", "Rejected"];
     const selectionItemKeys = ["under_review", "approved", "rejected"];

     const onPageChange = async (e, pageIndex, mStatus) => {
          try {
               const params = new URLSearchParams();
               params.append("skip", 20 * (pageIndex-1));
               params.append("limit", 20);
               if(mStatus){
                    params.append("status", mStatus);
               }
               else params.append("status", status);

               const res = await axiosInstance.get('admin/barber-forms', {
                    params: params
               });
               setErrorMessage(null);
               setData(res.data.data);
               setMaxDataLength(res.data.maxLength);
          } 
          catch (error) {
               setErrorMessage("Failed");
               console.log(error);
          }
     }

     useEffect(()=>{
          onPageChange(null, 1);
     }, []);

     return (
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         {/* <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton> */}
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Barber Form</h5>
                    </div>
                    <DropdownMenu items={selectionItems} onChange={(value, i)=> {
                              const status = selectionItemKeys[i];
                              setStatus(status);
                              onPageChange(null, 1, status);
                              console.log(status)
                         }}/>
               </div>
               <div className="__table_root">
                    <table cellspacing="0" cellpadding="0" className="table">
                    <tr>
                         { tableHead.map((value, i)=> <th>{value}</th>) }
                    </tr>
                    { errorMessage ? null : ( data.length != 0 ? data.map((value, i)=> <Item currentStatus={status} data={value} position={i}/>) : null) }
                    </table>
                    { data.length != 0 ? null : <ErrorMessage message='Empty'/> }
                    { errorMessage ? <ErrorMessage message={errorMessage}/> : null }
                    <div className='d-flex justify-content-between'>
                    <div></div>
                         <Pagination onChange={onPageChange} count={maxLength != 0 ? ~~((maxLength/20)+1) : 0} shape="rounded" />
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { _id , barberUserName, barberMobileNumber, barberStateName, services, date } = props.data;
     const [showing, setShowing] = useState(true); 

     const getMultiList = (list, col) => {
          let x = [];
          const new_list = [];
          for (let item of list){
              x.push(item);
              if(x.length == col){
                  new_list.push(x);
                  x = [];
              }
          }
          if(x.length != 0){
              new_list.push(x);
          }
          return new_list;
     }

     const onAction = async (status) => {
          setShowing(false);
          try {
               const rBody = new FormData();
               rBody.append("id", _id)
               rBody.append("status", status)
               const res = await axiosInstance.patch("admin/withdrawal-request", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               )
          } 
          catch (error) {
               Toast.error("Failed to update");
          }
     }

     if(!showing) return null;

     return(
          <tr>
               <td><span><a href={`/admin-barber-form-data?id=${_id}`}>#{_id.toUpperCase()}</a></span></td>
               <td><span>{barberUserName}</span></td>
               <td><span>{barberMobileNumber}</span></td>
               <td><span>{barberStateName}</span></td>
               <td>{getMultiList(services, 4).map((val, i)=> (
                        <div style={{padding: '0'}}>
                            { val.map(val=> <span className='c-access-name unselected' style={{background: "rgb(97, 97, 97, .3)"}} >{val}</span>) }
                        </div>
                     ))}
                </td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div style={{height: "300px", fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
               {props.message}
          </div>
     );
}

export default BarberForm;