import React, {useState} from 'react'
import axiosInstance from '../../http/axios';

import Toast from '../../toast';

import { TextField, Button, Fab, IconButton } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

import Header from '../header/Header';

import './Banner.css';
import DropdownMenu from '../dropdown_menu/DropdownMenu';

const CreateBanner = ()=>{

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          file: '',
     })
     const selectionItems = ["None"];

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const onChange = (event)=>{
          const { value, name } = event.target;
          setData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          });
          console.log(data);
     }

     const validate = () => {
          if(!data.file){
               Toast.error("Please upload banner image");
               return false;
          }
          return true;
     }

     const onSubmit = async ()=>{

          if(!validate()) return;

          setIsSending(true)
          try {
               const rBody = new FormData();
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               const res = await axiosInstance.post("admin/banner", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               )
               Toast.success('Success');
               setIsSending(false);
               window.history.go(-1)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed');
          }
     }

     return(
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>navigate(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Create banner</h5>
                    </div>
               </div>
               <div className="d-flex justify-content-center" style={{height:'auto', marginTop: '50px'}}>
                    <div className='notification_container d-flex align-items-center flex-column'>
                         <h2 className='text-center' style={{marginTop: '50px'}}>Banner</h2>
                         
                         
                         <div style={{ width: '100%', marginLeft: '35px'}}>
                              <div className='notification-import-image-root d-flex justify-content-center align-items-center' style={{marginTop: '20px'}}>
                                   { data.file ? <img style={{height: '100%', width: '100%'}} src={URL.createObjectURL(data.file)}/> : <div> <AddRounded /> Banner </div> }
                              </div>
                              <label htmlFor="upload-photo" style={{marginTop: '10px'}}>
                                   <input name="file" onChange={onChange} style={{ display: 'none' }} id="upload-photo" type="file" itemType='image/*' />
                                   <Fab color="primary" size="small" component="span" aria-label="add" variant="extended">
                                        <AddRounded /> Upload
                                   </Fab>
                              </label>
                         </div>
                         <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', marginBottom:"30px", cursor: isSending ? "progress" : 'pointer'}} className="__btn">Create</button>
                    </div>
               </div>
          </>
     );
}

export default CreateBanner;