const AccessiblePages = [
    {
         path: '/admin-dashboard',
         leble: 'Dashboard',
         class: 'mdi-home'
    },
    {
         path: '/admin-users',
         leble: 'User',
         class: 'mdi-account-group'
    },
    {
         path: '/admin-banner',
         leble: 'Banners',
         class: 'mdi-image-outline'
    },
    {
         path: '/admin-barbers',
         leble: 'Barbers',
         class: 'mdi-account-group'
    },
    {
         path: '/admin-barber-form',
         leble: 'Barber Form',
         class: 'mdi-format-align-left'
    },
    {
         path: '/admin-orders',
         leble: 'Orders',
         class: 'mdi-format-align-left'
    },
    {
         path: '/admin-services',
         leble: 'Services',
         class: 'mdi-face-agent'
    },
    {
         path: '/admin-withdrawal-requests',
         leble: 'Withdrawal request',
         class: 'mdi-cash-multiple'
    },
//     {
//          path: '/admin-notification',
//          leble: 'Notification',
//          class: 'mdi-bell-outline'
//     },
    {
         path: '/admin-setting',
         leble: 'Setting',
         class: 'mdi-cogs'
    },
];

export default AccessiblePages;