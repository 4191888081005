import React, {useEffect, useState} from 'react'
import axiosInstance from '../../http/axios';

import Toast from '../../toast';

import { TextField, Button, Fab, IconButton, Switch } from '@mui/material';
import AddRounded from '@mui/icons-material/AddRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';

import Header from '../header/Header';

import DropdownMenu from '../dropdown_menu/DropdownMenu';
import { useLocation } from 'react-router-dom';

const EditService = ()=>{

    const location = useLocation();

    const searchParam = new URLSearchParams(location.search);
     const id = searchParam.get("id");

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
          file: '',
          package: false,
          name: "",
          serviceCharge: 0,
          commission: 0,
          timeList: "",
          imageUrl: '',
     })
     const selectionItems = ["None"];

     const navigate = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };

     const getdata = async () => {
        try {
            const params = new URLSearchParams();
            params.append("id", id);
            const res = await axiosInstance.get('admin/service', {
                 params: params
            });
            setData(res.data);
       } 
       catch (error) {
            Toast.error('Failed');
       }
     }

     useEffect( ()=>{
        getdata()
   }, []);

     const onChange = (event)=>{
          const { value, name } = event.target;
          setData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          });
          console.log(data);
     }

     const validate = () => {
        if(!data.file && !data.imageUrl){
             Toast.error("Please upload image");
             return false;
        }
        if(!data.name){
             Toast.error("Please enter service name");
             return false;
        }
        if(!data.serviceCharge){
             Toast.error("Please enter service charge");
             return false;
        }
        if(!data.commission){
             Toast.error("Please enter commission");
             return false;
        }
        if(!data.timeList){
             Toast.error("Please enter delivery time");
             return false;
        }
        return true;
     }

     const onSubmit = async ()=>{

          if(!validate()) return;

          setIsSending(true)
          try {
               const rBody = new FormData();
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               const res = await axiosInstance.patch("admin/service-save-edit", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               )
               Toast.success('Success');
               setIsSending(false);
               window.history.go(-1)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed');
          }
     }

     return(
          <>
               <div className="d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>navigate(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Edit {data.package ? "Package" : "Service"}</h5>
                    </div>
               </div>
               <div className="d-flex justify-content-center" style={{height:'auto', marginTop: '0px'}}>
                    <div className='notification_container d-flex align-items-center flex-column'>
                         <h2 className='text-center' style={{marginTop: '50px'}}>{data.package ? "Package" : "Service"}</h2>
                         
                         
                         <div style={{ width: '100%', marginLeft: '35px'}}>
                              <div className='notification-import-image-root d-flex justify-content-center align-items-center' style={{marginTop: '20px'}}>
                                   { <img style={{height: '100%', width: '100%'}} src={data.file ? URL.createObjectURL(data.file) : data.imageUrl}/>}
                              </div>
                              <label htmlFor="upload-photo" style={{marginTop: '10px'}}>
                                   <input name="file" onChange={onChange} style={{ display: 'none' }} id="upload-photo" type="file" itemType='image/*' />
                                   <Fab color="primary" size="small" component="span" aria-label="add" variant="extended">
                                        <AddRounded /> Change
                                   </Fab>
                              </label>
                         </div>
                         <TextField name='name' value={data.name} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Service" type="text"/>
                         <TextField name='serviceCharge' value={data.serviceCharge} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Service Charge" type="number"/>
                         <TextField name='commission' value={data.commission} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Commission" type="number"/>
                         <TextField name='timeList' value={data.timeList} onChange={onChange} style={{width: '90%', marginTop: '20px', color: 'red'}} fullWidth label="Delivery Time" type="text"/>
                         
                         <button onClick={onSubmit} style={{width: '90%', marginTop: '30px', marginBottom:"30px", cursor: isSending ? "progress" : 'pointer'}} className="__btn">Save</button>
                    </div>
               </div>
          </>
     );
}

export default EditService;