import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import axiosInstance from './../../http/axios';

import Toast from '../../toast';
import Header from './../header/Header';

import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"

import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { IconButton } from '@mui/material';
import DropdownMenu from '../dropdown_menu/DropdownMenu';
import {Pagination} from '@mui/material';
import { TextField } from '@mui/material';
import NavLink from '../nav_link/NavLink';

const BarberFormFullPage = () => {

     const naviagte = (a)=> {
          if(typeof a == 'string'){
               window.location.href = a;
          }
          else if(typeof a == 'number'){
               window.history.go(a);
          }
     };
     const location = useLocation();

     const id = new URLSearchParams(location.search).get("id");

     const [isSending, setIsSending] = useState(false);
     const [data, setData] = useState({
        loginId: "",
        barberUserName: "",
        barberMobileNumber: "",
        barberStateName: "",
        barberAddress: "",
        barberAdharcardImage1: "",
        barberAdharcardImage2: "",
        
        // service details
        stateName: "",
        cityName: "",
        pincodes: "",
        areaDetails: "",
        services: [],
     });
    //  const [errorMessage, setErrorMessage] = useState(null);
     const [errorMessage, setErrorMessage] = useState("Loading");

     function numberKMBT (labelValue) {

          // Nine Zeroes for Billions
          return Math.abs(Number(labelValue)) >= 1.0e+9
      
          ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
          // Six Zeroes for Millions 
          : Math.abs(Number(labelValue)) >= 1.0e+6
      
          ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
          // Three Zeroes for Thousands
          : Math.abs(Number(labelValue)) >= 1.0e+3
      
          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
      
          : Math.abs(Number(labelValue));
      
      }

      function parseMsIntoReadableTime(milliseconds){
          //Get hours from milliseconds
          var hours = milliseconds / (1000*60*60);
          var absoluteHours = Math.floor(hours);
          var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
        
          //Get remainder from hours and convert to minutes
          var minutes = (hours - absoluteHours) * 60;
          var absoluteMinutes = Math.floor(minutes);
          var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;
        
          //Get remainder from minutes and convert to seconds
          var seconds = (minutes - absoluteMinutes) * 60;
          var absoluteSeconds = Math.floor(seconds);
          var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
        
        
          return h + ':' + m + ':' + s;
        }

     const toFirstLetterUpperCase = (str) => {
          if(str && str.length >= 2){
               return str.substring(0, 1).toUpperCase() + str.substring(1);
          }
          return str
     }

     const onChange = e => {
          setData({
               ...data,
               [e.target.name]: e.target.value,
          });
     }

     const validate = () => {
          if(!data.password){
               Toast.error("Please enter password");
               return false;
          }
          return true;
     }

     const onAction = async (status) => {
          if(isSending) return;
          // if(!validate()) return;

          setIsSending(true)
          try {
               const rBody = new FormData();
               Object.keys(data).forEach(key => rBody.append(key, data[key]));
               rBody.append("xstatus", status);
               const res = await axiosInstance.patch("admin/barber-form-data", rBody, 
                    {
                         headers: { "Content-Type": "multipart/form-data" }
                    }
               );

               Toast.success(toFirstLetterUpperCase(status));
               setIsSending(false)
               setTimeout(window.location.reload, 2000)
          } catch (error) {
               console.log(error);
               setIsSending(false)
               Toast.error('Failed to update');
          }
     }

     const addBalance = () => {
          const input = prompt("Add balance", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               balance: Number(data.balance) + Number(input)
          });
     }

     const removeBalance = () => {
          const input = prompt("Remove balance", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               balance: Number(data.balance) - Number(input)
          });
     }

     const addDiamond = () => {
          const input = prompt("Add Diamonds", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               myDiamonds: Number(data.myDiamonds) + Number(input)
          });
     }

     const removeDiamond = () => {
          const input = prompt("Remove Diamonds", 100);
          if(isNaN(input)) return;

          setData({
               ...data,
               myDiamonds: Number(data.myDiamonds) - Number(input)
          });
     }

     const getdata = async ()=> {
        try {
            const params = new URLSearchParams();
            params.append("id", id);

            const res = await axiosInstance.get('admin/barber-form-data', {
                 params: params
            });
            setErrorMessage(null);
            setData({
                 ...res.data,
            });

            console.log(res.data);
       } 
       catch (error) {
            setErrorMessage("Failed");
       }
     }

     useEffect( ()=>{
          getdata()
     }, []);

     if(errorMessage){
          return(
               <>
                    <div className="container d-flex justify-content-between">
                         <div style={{height: '80px'}} className='d-flex align-items-center'>
                              <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                              <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Form</h5>
                         </div>
                    </div>
                    <div className="container box d-flex justify-content-center align-items-center" style={{height: '90vh'}}>
                         <h1>{errorMessage}</h1>
                    </div>
               </>
          );
     }

     return (
          <>
               <div className="aa container d-flex justify-content-between">
                    <div style={{height: '80px'}} className='d-flex align-items-center'>
                         <IconButton onClick={()=>naviagte(-1)}><ArrowBackRounded/></IconButton>
                         <h5  style={{marginLeft: '10px', height: '100%', marginTop: '5px'}} className='d-flex align-items-center'>Form</h5>
                    </div>
               </div>
               <div className="container d-flex justify-content-between" >
                    <div className="box d-flex align-items-center flex-column" style={{width: '40%'}}>
                         <div className='container d-flex justify-content-between'>
                              <img src={data.barberAdharcardImage1||"logo192.png"} style={{width: '200px', height: '300px', marginTop: '30px', marginBottom: '20px'}}/>
                              <img src={data.barberAdharcardImage2||"logo192.png"} style={{width: '200px', height: '300px', marginTop: '30px', marginBottom: '20px'}}/>
                         </div>
                         <div className='container d-flex justify-content-between'>
                            <a className='btnx' style={{marginRight: "20px"}} href={data.barberAdharcardImage1}>View</a>
                            <a className='btnx' href={data.barberAdharcardImage2}>View</a>
                         </div>
                         <div style={{height: '1px', width: '100%', backgroundColor: 'rgb(180, 180, 180)', margin: '0px 50px'}}/>
                         { data.status == "rejected" || data.status == "approved" ? null : <div style={{width: '90%', marginTop: '10px'}}>
                              <button className='btnx' onClick={()=>onAction(`approved`)}>Approve</button>
                              <button className='btnx' onClick={()=>onAction(`rejected`)}>Reject</button>
                         </div>}
                         </div>
                    <div className="box d-flex justify-content-around row" style={{width: '57%'}}>
                         <div className="d-flex align-items-center flex-column col-6" style={{width: '50%'}}>
                                <p style={{width: '100%', marginLeft: '10px', marginTop: '20px'}}>Barber Details</p>
                              <TextField disabled name='loginId' value={data.loginId} onChange={onChange} style={{width: '100%'}} fullWidth label="Login ID" type="text" />
                              <TextField disabled name='barberUserName' value={data.barberUserName} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="Barber Name" type="text" />
                              <TextField disabled name='barberMobileNumber' value={data.barberMobileNumber} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="Mobile Number" type="text" />
                              <TextField disabled name='stateName' value={data.stateName} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="State Name" type="text" />
                              <TextField disabled name='barberAddress' value={data.barberAddress} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="Address" type="text" />
                         </div>
                         <div className="d-flex align-items-center flex-column col-6" style={{width: '50%'}}>
                                <p style={{width: '100%', marginLeft: '10px', marginTop: '20px'}}>Service Details</p>
                              <TextField disabled name='stateName' value={data.stateName} onChange={onChange} style={{width: '100%'}} fullWidth label="State Name" type="text" />
                              <TextField disabled name='cityName' value={data.cityName} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="City Name" type="text" />
                              <TextField name='pincodes' value={data.pincodes} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="Pincode List" type="text" />
                              <TextField disabled name='areaDetails' value={data.areaDetails} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="Area Details" type="text" />
                              <TextField disabled name='services' value={data.services} onChange={onChange} style={{width: '100%', marginTop: '20px'}} fullWidth label="Services" type="text" />
                         </div>
                         
                    </div>
               </div>
          </>
     );
}

const Item = (props) => {
     const { _id, userName, userId, profilePhoto, country, gender, agentStatus, hostStatus, vipEnabled } = props.data;

     const getStatusView = (status) => {
          // rejected, submited, enabled, desabled, no_action
          var text = "";
          var className = "";
          if(status == "no_action"){
               text = "No action";
               className = "status_c_yellow";
          }
          else if(status == "submited"){
               text = "Submited";
               className = "status_c_blue";
          }
          else if(status == "desabled"){
               text = "Desabled";
               className = "status_c_red";
          }
          else if(status == "rejected"){
               text = "Rejected";
               className = "status_c_red";
          }
          else if(status == "enabled"){
               text = "Enabled";
               className = "status_c_green";
          }
          else {
               className = "status_c_yellow";
               text = "None";
          }

          return <span className={className + " item_status_view"}>{text}</span>
     }

     return(
          <tr>
               <td>
                    <img className='img_profile_photo' src={profilePhoto ? profilePhoto : "logo192.png"}/> 
                    <NavLink to="/" a className={isActive => "nav_link " + (isActive ? "" : "navlink_unselected")}>
                         {userName}
                    </NavLink>
               </td>
               <td>{userId}</td>
               <td>{country}</td>
               <td><span>{gender.charAt(0).toUpperCase() + gender.slice(1)}</span></td>
               <td>{getStatusView(agentStatus)}</td>
               <td>{getStatusView(hostStatus)}</td>
               <td><span className={"item_status_view " + (vipEnabled ? "status_c_green" : "status_c_red")}>{vipEnabled ? "Active" : "None"}</span></td>
          </tr>
     );
};

const ErrorMessage = (props) => {
     return (
          <div className='d-flex align-items-center justify-content-center' style={{height: "300px", fontSize: '16px'}}>
               {props.message}
          </div>
     );
}

export default BarberFormFullPage;