import React, {useState, useEffect} from 'react'

// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import PopupState, { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state';

// import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
// import { IconButton } from '@mui/material';

const DropdownMenu = (props) => {

     const items = props.items||[];
     const [selected, setSelected] = useState(props.defaultValue ? props.defaultValue : items[0]);

     const handelChange = e => {
          const i = parseInt(e.target.value);
          const value = items[i];
          setSelected(value)
          if(typeof props.onChange == 'function'){
               props.onChange(value, i);
          }
     }

     useEffect(()=> {
          setSelected(props.defaultValue ? props.defaultValue : items[0])
     }, []);

     return (
          <div className='w-10 rounded mt-4'>
                    <select className='form-select' onChange={handelChange}>
                         {
                              items.map((val, i)=> (
                                   <option value={i}>{val}</option>
                              ))
                         }
                    </select>
          </div>
     );
}


export default DropdownMenu;